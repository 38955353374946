import Image from "react-bootstrap/Image";

export function MainIntroduction2Mobile() {
    return (
        <div className='position-relative mt-3' style={{maxWidth: '100%'}}>
            <Image src="images/main/mobile/introduction2BG.png" style={{width: '100%', height: 'auto'}}/>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '22.5%',
                left: '27%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '4.5vw',
                fontWeight: '600',
                fontStyle: 'italic',
            }}>
                Clowning the Fame Game
            </div>
            <div className='position-absolute w-100 my-text' style={{
                textAlign: 'center',
                top: '40%',
                left: '50%',
                transform: 'translate(-50%, 0%)',
                color: 'black',
                fontSize: '3.5vw',
                fontWeight: '600',
            }}>
                Ever watched some random person<br/>
                blow up on the web and thought, <spen style={{fontWeight: '800'}}>"Am<br/></spen>
                <spen style={{fontWeight: '800'}}>I the joke here?"<br/><br/></spen>
                Well, grab your popcorn, 'cause we're<br/>
                turning the tables on the meme gods.<br/><br/>
                Cyber Clown Token is our way of<br/>
                poking fun at the online fame frenzy.<br/><br/>
                From overnight celebs with zero skills<br/>
                to legit talents getting ghosted, it's<br/>
                time to meme-shame the system.<br/>
            </div>
        </div>
    )
}