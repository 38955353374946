import Image from "react-bootstrap/Image";

export function AboutIntroduction1Pc() {
    return (
        <div className='position-relative mt-3' style={{maxWidth: '100%'}}>
            <Image src="images/about/pc/introduction1BG.png" style={{width: '100%', height: 'auto'}}/>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '76%',
                left: '8.5%',
                transform: 'translate(0%, 0%)',
                color: 'white',
                fontSize: '1.4vw',
                fontWeight: '500',
            }}>
                Ladies and gentlemen, step right up to witness the birth of a digital<br/>
                phenomenon – Cyber Clown Token! <br/><br/>
                We're not just a memecoin; we're a memecoin with a mission, and we're here to<br/>
                revolutionize the way you experience the digital world.
            </div>
        </div>
    )
}