import Image from "react-bootstrap/Image";

export function MainIntroduction1Pc() {
    return (
        <div className='position-relative' style={{maxWidth: '100%'}}>
            <Image src="images/main/pc/introduction1BG.png" style={{width: '100%', height: 'auto'}}/>
            <div className='position-absolute w-100 my-text' style={{
                textAlign: 'center',
                top: '21%',
                left: '50%',
                transform: 'translate(-50%, 0%)',
                color: 'black',
                fontSize: '1.4vw',
                lineHeight: '1.2',
                fontWeight: '600',
                fontStyle: 'italic',
            }}>
                Cyber Clown Token:<br/>
                Where Memes and Talent Collide
            </div>
            <div className='position-absolute w-100 my-text' style={{
                textAlign: 'center',
                top: '40%',
                left: '50%',
                transform: 'translate(-50%, 0%)',
                color: 'black',
                fontSize: '1vw',
                fontWeight: '500',
            }}>
                Welcome to the circus, friends!<br/>
                Cyber Clown Token isn't just another memecoin; we're the wild ride you've<br/>
                been waiting for.<br/>
                We're here to bring the laughs, discover hidden talents, and turn the internet<br/>
                upside down.
            </div>
        </div>
    )
}