import Image from "react-bootstrap/Image";

export function AboutIntroduction3Mobile() {
    return (
        <div className='position-relative mt-3' style={{maxWidth: '100%'}}>
            <Image src="images/about/mobile/introduction3BG.png" style={{width: '100%', height: 'auto'}}/>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '3%',
                left: '25%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '3.5vw',
                fontWeight: '500',
                fontStyle: 'italic'
            }}>
                The Cyber Clown Token Experience
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '7.1%',
                left: '17%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '3.5vw',
                fontWeight: '500',
                lineHeight:'200%'
            }}>
                With a total token supply of<br/>
                <spen style={{fontWeight: '700'}}>180,500,000,000</spen>, we're bringing the<br/>
                circus to the blockchain like never<br/>
                before. Cyber Clown Token is your<br/>
                ticket to a realm of endless<br/>
                possibilities, where laughs meet<br/>
                talent, and where rewards rain down<br/>
                like confetti.
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '21.6%',
                left: '30%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '3.8vw',
                fontWeight: '500',
                fontStyle: 'italic'
            }}>
                Tokenomics that Make Sense
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '25%',
                left: '15%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '3.5vw',
                fontWeight: '500',
                lineHeight:'200%'

            }}>
                <spen style={{fontWeight: '700'}}>We believe in transparency and<br/></spen>
                <spen style={{fontWeight: '700'}}>community-driven growth.</spen>That's<br/>
                why we've allocated 40% for our<br/>
                Presale, ensuring early supporters get<br/>
                in on the fun. Another 50% goes into<br/>
                Liquidity, securing a stable foundation<br/>
                for the project. The remaining 10% is<br/>
                reserved for our dedicated Community<br/>
                Management Team, the ringmasters of<br/>
                this circus.
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '39.3%',
                left: '40%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '3.8vw',
                fontWeight: '500',
                fontStyle: 'italic'
            }}>
                The Phases of Fun
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '42.7%',
                left: '15%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '3.5vw',
                fontWeight: '500',
                lineHeight:'200%'

            }}>
                But hold on to your clown hats,<br/>
                because the real excitement begins in<br/>
                Phase 2! We're launching the<br/>
                Gamestar+ platform, a gaming<br/>
                paradise like no other. It's where<br/>
                <spen style={{fontWeight: '700'}}>entertainment and blockchain</spen><br/>
                <spen style={{fontWeight: '700'}}>collide,</spen> with the <spen style={{fontWeight: '700'}}>integration of the</spen><br/>
                <spen style={{fontWeight: '700'}}>cutting-edge ERC-3225 standard.</spen> Get<br/>
                ready for a gaming experience that's<br/>
                out of this world.
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '56.88%',
                left: '30%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '3.8vw',
                fontWeight: '500',
                fontStyle: 'italic'
            }}>
                Empowering the Community
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '60.6%',
                left: '17%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '3.5vw',
                fontWeight: '500',
                lineHeight:'200%'

            }}>
                And that's not all, folks! In Phase 3,<br/>
                <spen style={{fontWeight: '700'}}>we're putting the power in your</spen><br/>
                <spen style={{fontWeight: '700'}}>hands with the Talent Voting<br/></spen>
                <spen style={{fontWeight: '700'}}>Platform.</spen>You, the incredible Cyber<br/>
                Clown Token community members,<br/>
                will have the say in which talents we<br/>
                support next. It's a true democracy of<br/>
                laughter, where your voice matters.
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '74.4%',
                left: '39.3%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '3.8vw',
                fontWeight: '500',
                fontStyle: 'italic'
            }}>
                Join the Circus
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '77.8%',
                left: '12.5%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '3.5vw',
                fontWeight: '500',
                lineHeight:'200%'
            }}>
                So, why Cyber Clown Token? Because<br/>
                we're not just another project; <spen style={{fontWeight: '700'}}>we're a</spen><br/>
                <spen style={{fontWeight: '700'}}>movement. We're about laughter and</spen><br/>
                <spen style={{fontWeight: '700'}}>talent, about fairness and fun.</spen> We're<br/>
                here to make the internet a better place,<br/>
                where deserving creators shine, and the<br/>
                community thrives.<br/>
                <br/>
                Don your clown nose and become part of<br/>
                the circus. Cyber Clown Token is more<br/>
                than just a cryptocurrency; it's a<br/>
                celebration of <spen style={{fontWeight: '700'}}>creativity, a symphony of</spen><br/>
                <spen style={{fontWeight: '700'}}>laughter, and a promise of exciting</spen><br/>
                <spen style={{fontWeight: '700'}}>things to come.</spen><br/>
                <br/>
                Welcome to the future of entertainment.<br/>
                <spen style={{fontWeight: '700'}}>Welcome to Cyber Clown Token. 🌟🤡🎉</spen>
            </div>
        </div>
    )
}