import {NavbarMobile} from "../MainPage/Components/Mobile/NavbarMobile";
import {AboutIntroduction1Mobile} from "./Components/Mobile/AboutIntroduction1Mobile";
import {AboutIntroduction2Mobile} from "./Components/Mobile/AboutIntroduction2Mobile";
import {AboutIntroduction3Mobile} from "./Components/Mobile/AboutIntroduction3Mobile";
import {FooterMobile} from "../MainPage/Components/Mobile/FooterMobile";
import {NavbarPc} from "../MainPage/Components/PC/NavbarPc";
import {AboutIntroduction1Pc} from "./Components/PC/AboutIntroduction1Pc";
import {AboutIntroduction2Pc} from "./Components/PC/AboutIntroduction2Pc";
import {FooterPc} from "../MainPage/Components/PC/FooterPc";

export function AboutPageMobile() {
    return (
        <div className='justify-content-md-center d-flex' style={{width: '100%', background: '#000000'}}>
            <div>
                <NavbarMobile></NavbarMobile>
                <div style={{
                    paddingBottom: '22.5%'
                }}></div>
                <AboutIntroduction1Mobile/>
                <AboutIntroduction2Mobile/>
                <AboutIntroduction3Mobile/>
                <FooterMobile/>
            </div>
        </div>
    )
}