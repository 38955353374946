import MediaQuery from 'react-responsive';

import {MainPageMobile} from "./MainPageMobile";
import {MainPagePc} from "./MainPagePc";

export function MainPage() {
    return (
        <div>
            <MediaQuery minWidth={768}>
                {(matches) =>
                    matches
                        ? <MainPagePc/>
                        : <MainPageMobile/>
                }
            </MediaQuery>
        </div>
    )
}