import {NavbarPc} from "./Components/PC/NavbarPc";
import {WelcomePc} from "./Components/PC/WelcomePc";
import {MainIntroduction1Pc} from "./Components/PC/MainIntroduction1Pc";
import {MainIntroduction2Pc} from "./Components/PC/MainIntroduction2Pc";
import {MainIntroduction3Pc} from "./Components/PC/MainIntroduction3Pc";
import {MainIntroduction4Pc} from "./Components/PC/MainIntroduction4Pc";
import {MainIntroduction5Pc} from "./Components/PC/MainIntroduction5Pc";
import {MainIntroduction6Pc} from "./Components/PC/MainIntroduction6Pc";
import {MainIntroduction7Pc} from "./Components/PC/MainIntroduction7Pc";
import {FooterPc} from "./Components/PC/FooterPc";
import {MainTimeCountPc} from "./Components/PC/MainTimeCountPc";
import {NewWelcomePc} from "./Components/PC/NewWelcomePc";

export function MainPagePc() {
    return (
        <div className='justify-content-md-center d-flex' style={{width: '100%', background: '#000000'}}>
            <div style={{width: '100%', border: '5px solid white', boxSizing: 'border-box'}}>
                <NavbarPc></NavbarPc>
                <NewWelcomePc></NewWelcomePc>
                <MainIntroduction1Pc></MainIntroduction1Pc>
                <MainIntroduction2Pc></MainIntroduction2Pc>
                <MainIntroduction3Pc></MainIntroduction3Pc>
                <MainIntroduction4Pc></MainIntroduction4Pc>
                <MainIntroduction5Pc></MainIntroduction5Pc>
                <MainIntroduction6Pc></MainIntroduction6Pc>
                <MainIntroduction7Pc></MainIntroduction7Pc>
                <FooterPc></FooterPc>
            </div >
        </div>
    )
}