import {MainPage} from "./Pages/MainPage/MainPage";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Route, Routes} from 'react-router-dom';
import {AboutPage} from "./Pages/AboutPage/AboutPage";

import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react'
import { WagmiConfig } from 'wagmi'
import { arbitrum, mainnet } from 'viem/chains'

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = '2e3183c3eb86ddd88d13ca929648e49d'

// 2. Create wagmiConfig
const metadata = {
    name: 'Web3Modal',
    description: 'Web3Modal Example',
    url: 'https://web3modal.com',
    icons: ['https://avatars.githubusercontent.com/u/37784886']
}

const chains = [mainnet, arbitrum]
const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata })

// 3. Create modal
createWeb3Modal({ wagmiConfig, projectId, chains })

function App() {
    return (
        <WagmiConfig config={wagmiConfig}>
            <Routes>
                <Route path='/'>
                    <Route index element={<MainPage />} />
                    <Route path='/about' element={<AboutPage />} />
                </Route>
            </Routes>
        </WagmiConfig>
    );
}

export default App;
