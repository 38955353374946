import {NavbarMobile} from "./Components/Mobile/NavbarMobile";
import {MainTimeCountUpMobile} from "./Components/Mobile/MainTimeCountUpMobile";
import {MainTimeCountDownMobile} from "./Components/Mobile/MainTimeCountDownMobile";
import {MainIntroduction1Mobile} from "./Components/Mobile/MainIntroduction1Mobile";
import {MainIntroduction2Mobile} from "./Components/Mobile/MainIntroduction2Mobile";
import {MainIntroduction3Mobile} from "./Components/Mobile/MainIntroduction3Mobile";
import {MainIntroduction4Mobile} from "./Components/Mobile/MainIntroduction4Mobile";
import {MainIntroduction5Mobile} from "./Components/Mobile/MainIntroduction5Mobile";
import {MainIntroduction6Mobile} from "./Components/Mobile/MainIntroduction6Mobile";
import {FooterMobile} from "./Components/Mobile/FooterMobile";
import {NavbarPc} from "./Components/PC/NavbarPc";
import {NewWelcomePc} from "./Components/PC/NewWelcomePc";
import {MainIntroduction1Pc} from "./Components/PC/MainIntroduction1Pc";
import {MainIntroduction2Pc} from "./Components/PC/MainIntroduction2Pc";
import {MainIntroduction3Pc} from "./Components/PC/MainIntroduction3Pc";
import {MainIntroduction4Pc} from "./Components/PC/MainIntroduction4Pc";
import {MainIntroduction5Pc} from "./Components/PC/MainIntroduction5Pc";
import {MainIntroduction6Pc} from "./Components/PC/MainIntroduction6Pc";
import {MainIntroduction7Pc} from "./Components/PC/MainIntroduction7Pc";
import {FooterPc} from "./Components/PC/FooterPc";
import {NewWelcomeMobile} from "./Components/Mobile/NewWelcomeMobile";


export function MainPageMobile() {

    return (
        <div className='justify-content-md-center d-flex' style={{width: '100%', background: '#000000'}}>
            <div>
                <NavbarMobile></NavbarMobile>
                <div style={{
                    height: '80.3px',
                }}></div>
                {/*<MainTimeCountUpMobile></MainTimeCountUpMobile>*/}
                {/*<MainTimeCountDownMobile></MainTimeCountDownMobile>*/}
                <NewWelcomeMobile></NewWelcomeMobile>
                <MainIntroduction1Mobile></MainIntroduction1Mobile>
                <MainIntroduction2Mobile></MainIntroduction2Mobile>
                <MainIntroduction3Mobile></MainIntroduction3Mobile>
                <MainIntroduction4Mobile></MainIntroduction4Mobile>
                <MainIntroduction5Mobile></MainIntroduction5Mobile>
                <MainIntroduction6Mobile></MainIntroduction6Mobile>
                <FooterMobile></FooterMobile>
            </div>
        </div>
    )
}