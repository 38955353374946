import Image from "react-bootstrap/Image";
import React, {useState, useEffect} from 'react';
import CountdownTimerPc from "../PC/CountdownTimerPc";
import CountdownTimerMobile from "./CountdownTimerMobile";
import CCTCountPC from "../PC/CCTCountPC";
import D223SoldFramePC from "../PC/D223SoldFramePC";
import ConnectOrBuyButtonPC from "../PC/ConnectOrBuyButtonPC";
import CCTCountMobile from "./CCTCountMobile";
import D223SoldFrameMobile from "./D223SoldFrameMobile";
import ConnectOrBuyButtonMobile from "./ConnectOrBuyButtonMobile";

export function NewWelcomeMobile() {
    const [ethCount, setEthCount] = useState(0);

    return (
        <div className='position-relative' style={{maxWidth: '100%'}}>
            <Image src="images/main/mobile/newWelcome.png" style={{width: '100%', height: 'auto'}}/>
            {/*倒數時間條*/}
            <Image src="images/main/pc/timeCounterBG_2.png" className='position-absolute' style={{
                width: '77.5%',
                bottom: '36%',
                left: '11%',
                transform: 'translate(0%, 0%)',
            }}>
            </Image>
            <div className='position-absolute' style={{
                width: '77.5%',
                bottom: '40%',
                left: '11%',
                color: "white",
                transform: 'translate(0%, -50%)',
            }}>
                <CountdownTimerMobile></CountdownTimerMobile>
            </div>

            <CCTCountMobile width={'50%'} height={'17.5%'} top={'70%'} left={'25%'} onResultChange={(value) => setEthCount(value)}></CCTCountMobile>
            <D223SoldFrameMobile width={'45%'} height={'3%'} bottom={'32.5%'} left={'27.5%'}></D223SoldFrameMobile>
            <ConnectOrBuyButtonMobile width={'30%'} height={'3.5%'} bottom={'8.5%'} left={'35.5%'} buyEthValue={ethCount}></ConnectOrBuyButtonMobile>
        </div>
    )
}