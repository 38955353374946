import Image from "react-bootstrap/Image";

export function MainIntroduction3Mobile() {
    return (
        <div className='position-relative mt-3' style={{maxWidth: '100%'}}>
            <Image src="images/main/mobile/introduction3BG.png" style={{width: '100%', height: 'auto'}}/>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '7.5%',
                left: '25%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '4.5vw',
                fontWeight: '600',
                fontStyle: 'italic',
            }}>
                Laughs and Gains, Baby!
            </div>
            <div className='position-absolute w-100 my-text' style={{
                textAlign: 'center',
                top: '36.5%',
                left: '50%',
                transform: 'translate(-50%, 0%)',
                color: 'black',
                fontSize: '3.5vw',
                fontWeight: '600',
            }}>
                But hold up, it's not all jest and jesting.<br/><br/>

                While we're clowning around, we're<br/>
                also cooking up some potential gains.<br/><br/>

                Yeah, you heard that right – you could<br/>
                be laughing all the way to the digital<br/>
                bank.<br/><br/>

                <spen style={{fontWeight: '800'}}>Who said memes can't pay the bills?</spen>
            </div>
        </div>
    )
}