import Image from "react-bootstrap/Image";

export function MainIntroduction1Mobile() {
    return (
        <div className='position-relative mt-3' style={{maxWidth: '100%'}}>
            <div className='w-100 align-content-center' style={{
                    textAlign: 'center',
                    color: '#F4BE35',
                    height: '15vh',
                    fontSize: '5vw',
                    fontWeight: '600',
            }}>
                Get Ready to Clown The Fame:<br/>
                Cyber Clown Token!<br/>
            </div>
            <Image src="images/main/mobile/introduction1BG.png" style={{width: '100%', height: 'auto'}}/>
            <div className='position-absolute w-100 my-text' style={{
                textAlign: 'center',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, 0%)',
                color: 'white',
                fontSize: '3.5vw',
                fontWeight: '400',
            }}>
                Hey there, you fearless cybers voyagers!<br/>
                Buckle up, 'cause we're about to spill the tea<br/>
                on a whole new level of meme-worthy<br/>
                madness.<br/>
                Enter the realm of Cyber Clown Token – the<br/>
                memecoin that's here to roast the roasters<br/>
                and meme the memers.
            </div>
        </div>
    )
}