import Image from "react-bootstrap/Image";

export function MainIntroduction5Pc() {
    return (
        <div className='mt-4 position-relative' style={{maxWidth: '100%', background: '#000000'}}>
            <Image src="images/main/pc/introduction5BG.png" style={{width: '100%', height: 'auto'}}/>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '8.25%',
                left: '40%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '1.62vw',
                lineHeight: '1.2',
                fontWeight: '600',
                fontStyle: 'italic',
            }}>
                Laughs and Gains, Baby!
            </div>
        </div>
    )
}