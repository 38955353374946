import Image from "react-bootstrap/Image";

export const FooterPc = () => {
    return (
        <div className='mt-4 position-relative' style={{maxWidth: '100%', background: '#000000'}}>
            <Image src="images/main/pc/footerBG.png" style={{width: '100%', height: 'auto'}}/>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '7.8%',
                left: '3.7%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '1.7vw',
                lineHeight: '1.2',
                fontWeight: '700',
            }}>
                🌐Join the Meme Team
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'left',
                top: '20%',
                left: '4.2%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '1vw',
                lineHeight: '1.2',
                fontWeight: '500',
            }}>
                Are you all about crypto, memes, and the wild world of the web? Welcome to the squad, meme warrior!<br/>
                Cyber Clown Token is the place where memes and money collide in the most gloriously chaotic way possible.<br/>
                Join us as we ride this meme-mobile to parts unknown.
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '50%',
                left: '4.2%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '1.6vw',
                lineHeight: '1.2',
                fontWeight: '700',
            }}>
                Cyber Clown Commandments:
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '60%',
                left: '4.3%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '1.1vw',
                lineHeight: '1.2',
                fontWeight: '700',
            }}>
                Thou shalt not take life too seriously.
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '65%',
                left: '4.3%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '1.1vw',
                lineHeight: '1.2',
                fontWeight: '700',
            }}>
                Thou shalt meme responsibly (and hilariously).
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '70%',
                left: '4.3%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '1.1vw',
                lineHeight: '1.2',
                fontWeight: '700',
            }}>
                Thou shalt remember: Clowns run this town.
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '90%',
                left: '9.4%',
                transform: 'translate(0%, 0%)',
                color: 'white',
                fontSize: '1vw',
                lineHeight: '1.2',
                fontWeight: '700',
            }}>
                Cyber Clown Token
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '90.5%',
                left: '63.5%',
                transform: 'translate(0%, 0%)',
                color: 'white',
                fontSize: '0.8vw',
                lineHeight: '1.2',
                fontWeight: '500',
            }}>
                © 2023 Cyber Clown Token. Terms and Conditions Apply.
            </div>
        </div>
    )
}