import {NavbarPc} from "../MainPage/Components/PC/NavbarPc";
import {FooterPc} from "../MainPage/Components/PC/FooterPc";
import {AboutIntroduction1Pc} from "./Components/PC/AboutIntroduction1Pc";
import {AboutIntroduction2Pc} from "./Components/PC/AboutIntroduction2Pc";

export function AboutPagePc() {
    return (
        <div className='justify-content-md-center d-flex' style={{width: '100%', background: '#000000'}}>
            <div style={{width: '70%', border: '5px solid white', boxSizing: 'border-box'}}>
                <NavbarPc></NavbarPc>
                <AboutIntroduction1Pc/>
                <AboutIntroduction2Pc/>
                <FooterPc></FooterPc>
            </div >
        </div>
    )
}