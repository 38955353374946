import Image from "react-bootstrap/Image";

export function FooterMobile() {
    return (
        <div className='position-relative mt-3' style={{maxWidth: '100%'}}>
            <Image src="images/main/mobile/footerBG.png" style={{width: '100%', height: 'auto'}}/>
            <div className='position-absolute my-text' style={{
                textAlign: 'left',
                top: '2.5%',
                left: '5%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '5vw',
                fontWeight: '700',
            }}>
                🌐 Join the Meme Team
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'left',
                top: '10%',
                left: '5.5%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '2.8vw',
                fontWeight: '600',
            }}>
                Are you all about crypto, memes, and the wild world<br/>
                of the web?<br/>
                Welcome to the squad, meme warrior!<br/><br/>
                Cyber Clown Token is the place where memes and<br/>money collide in the most gloriously chaotic way<br/>possible.<br/><br/>
                Join us as we ride this meme-mobile to parts
                <br/>unknown.
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '52%',
                left: '5.5%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '4.5vw',
                lineHeight: '1.2',
                fontWeight: '700',
            }}>
                Cyber Clown Commandments:
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '58%',
                left: '5.5%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '3.2vw',
                lineHeight: '1.2',
                fontWeight: '700',
            }}>
                Thou shalt not take life too seriously.
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '62.2%',
                left: '5.5%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '3.2vw',
                lineHeight: '1.2',
                fontWeight: '700',
            }}>
                Thou shalt meme responsibly (and hilariously).
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '66%',
                left: '5.5%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '3.2vw',
                lineHeight: '1.2',
                fontWeight: '700',
            }}>
                Thou shalt remember: Clowns run this town.
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '87%',
                left: '20%',
                transform: 'translate(0%, 0%)',
                color: 'white',
                fontSize: '4vw',
                lineHeight: '1.2',
                fontWeight: '600',
                fontStyle: 'italic'
            }}>
                Cyber Clown Token
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '93%',
                left: '19.5%',
                transform: 'translate(0%, 0%)',
                color: 'white',
                fontSize: '2.5vw',
                lineHeight: '1.2',
                fontWeight: '500',
            }}>
                © 2023 Cyber Clown Token. Terms and Conditions Apply.
            </div>
        </div>
    )
}