import React from "react";
import {useWeb3Modal} from "@web3modal/wagmi/react";
import {Button} from "react-bootstrap";
import {useAccount, useContractWrite} from "wagmi";
import cctABI from "../../../../ABI/cctABI.json";
import {parseEther} from "viem";

const ConnectOrBuyButtonPC = ({width, height, bottom, left, buyEthValue}) => {
    const {isDisconnected} = useAccount()
    const {open} = useWeb3Modal()

    const {data, isLoading, isSuccess, write} = useContractWrite({
        address: '0x43c2fd02e50b5b3c8b289a987dac98ceaf933514',
        abi: cctABI,
        functionName: 'buyCCTWithETH',
    })

    function handleOpenClick() {
        if (isDisconnected){
            open();
        }else{
            if (buyEthValue === 0 || buyEthValue === null)
                return;

            write({
                value: parseEther(buyEthValue),
            });
        }
    }

    let buttonText = "Connect Wallet";
    let buttonColor = '#F7931E';

    if (!isDisconnected) {
        buttonText = "BUY NOW";
        buttonColor = '#E32026';
    }

    return (
        <Button
            style={{
                position: 'absolute',
                width: width,
                height: height,
                backgroundColor: buttonColor,
                border: '0.15vw solid white',
                bottom: bottom,
                left: left,
                borderRadius: '2vw',
                transform: 'translate(0%, -50%)',
                color: 'white',
                fontWeight: 'bold',
                fontSize: '1.15vw',
            }}
            onClick={handleOpenClick}
        >
            {buttonText}
        </Button>
    );
}

export default ConnectOrBuyButtonPC;