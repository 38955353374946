import React, {useEffect, useState} from 'react';
import Image from "react-bootstrap/Image";
import axios from "axios";

const CCTCountPC = ({width, height, top, left, onResultChange}) => {
    const [inputValue, setInputValue] = useState(0); // 初始值设置为 0

    const handleInputChange = (e) => {
        const newValue = e.target.value;
        // 允许输入非零开头的数字和小数点（但不允许负数和多个小数点）
        if (/^[0-9]\d*(\.\d*)?$/.test(newValue) || newValue === '') {
            setInputValue(newValue);
        }
    };

    // 这里可以添加计算逻辑
    const calculatedResult = calculateValue(inputValue); // 举例：直接显示输入值

    function calculateValue(value) {
        let result = value;

        // 傳回ETH的數量
        onResultChange(result);

        // 轉換CCT的數量
        result = result * 100000;
        return result;
    }

    return (
        <div style={{
            position: 'absolute',
            width: width,
            height: height,
            top: top,
            left: left,
        }}>
            <div style={{
                position: 'relative',
                width: '100%',
                height: '50%',
            }}>
                <Image
                    src="images/main/pc/CCTCountBG1.png"
                    className='position-absolute'
                    style={{
                        width: '100%',
                        top: '0%',
                        left: '0%',
                    }}
                />
                {/* 输入框 */}
                <input
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    style={{
                        position: 'absolute',
                        top: '48.5%',
                        left: '2.75%',
                        zIndex: 2,
                        padding: '0px',
                        color: 'white',
                        background: 'transparent',
                        border: 'none',
                        fontSize: '1.75vw',
                    }}
                />
            </div>

            <div style={{
                position: 'relative',
                width: '100%',
                height: '50%',
            }}>
                <Image
                    src="images/main/pc/CCTCountBG2.png"
                    className='position-absolute'
                    style={{
                        width: '100%',
                        top: '0%',
                        left: '0%',
                    }}
                />
                {/* 显示计算结果 */}
                <span
                    style={{
                        position: 'absolute',
                        top: '26.5%',
                        left: '2.75%',
                        zIndex: 2,
                        color: 'white',
                        fontSize: '1.75vw',
                    }}
                >
                    {calculatedResult}
                </span>
            </div>
        </div>
    );
}

export default CCTCountPC;