import {Button, Offcanvas, Row} from "react-bootstrap";
import {useState} from "react";
import {Link} from "react-router-dom";
import {useAccount, usePrepareContractWrite, useWaitForTransaction} from "wagmi";
import {useWeb3Modal} from "@web3modal/wagmi/react";

import {useContractWrite} from 'wagmi'
import cctABI from '../../../../ABI/cctABI.json';
import {parseEther} from "viem";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {FaMedium, FaTwitter} from "react-icons/fa";

export function NavbarMobile() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const {isDisconnected} = useAccount()
    const {open} = useWeb3Modal()

    function handleButtonClick() {
        setShow(false);
        open();
    }

    return (
        <>
            <div className='position-fixed bg-black w-100 z-1'>
                <div className='position-relative m-3' style={{width: '100%'}}>
                    <img
                        src="images/logo.png"
                        width="57.5px"
                        height="auto"
                        className="d-inline-block align-top"
                        alt="React Bootstrap logo"
                    />
                    {isDisconnected ?
                        <Button variant="primary"
                                className="position-absolute"
                                style={{
                                    borderRadius: '10vw',
                                    top: '52.5%',
                                    right: '17.5%',
                                    transform: 'translate(-50%, -50%)',
                                    background: '#42a5fe',
                                    border: '0',
                                    fontWeight: '800',
                                    fontSize: '2.75vw',
                                    padding: '0.6vw',
                                }}
                                onClick={handleButtonClick}
                        >
                            Connect
                        </Button>
                        : <div></div>
                    }
                    <Button variant="primary"
                            className="position-absolute"
                            onClick={handleShow}
                            style={{
                                top: '50%',
                                right: '0%',
                                transform: 'translate(-50%, -50%)',
                                background: 'black',
                                border: '0',
                            }}
                    >
                        <img
                            src="images/menuButton.png"
                            width="30px"
                            height="auto"
                            className="d-inline-block"
                            alt="React Bootstrap logo"
                        />
                    </Button>
                </div>
            </div>
            <Offcanvas show={show}
                       onHide={handleClose}
                       style={{
                           width: '100%',
                           color: 'white',
                           background: 'black',
                       }}
            >
                <Offcanvas.Header>
                    <Offcanvas.Title>
                        <img
                            src="images/logo.png"
                            width="57.5px"
                            height="auto"
                            className="d-inline-block align-top"
                            alt="React Bootstrap logo"
                        />
                    </Offcanvas.Title>
                    <Button
                        onClick={handleClose}
                        style={{
                            transform: 'translate(0%, 15%)',
                            background: 'black',
                            border: '0',
                        }}
                    >
                        <img
                            src="images/closeButton.png"
                            width="25px"
                            height="auto"
                            className="d-inline-block"
                            alt="React Bootstrap logo"
                        />
                    </Button>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Row className='ms-1'>
                        <Link
                            to='/'
                            style={{
                                padding: 0,
                                color: 'white',
                                fontSize: '8vw',
                                fontWeight: '800',
                                textDecoration: 'none'
                            }}>
                            Home
                        </Link>
                    </Row>
                    <Row className='ms-1 mt-2'>
                        <Link
                            to='/about'
                            style={{
                                padding: 0,
                                color: 'white',
                                fontSize: '8vw',
                                fontWeight: '800',
                                textDecoration: 'none'
                            }}>
                            About
                        </Link>
                    </Row>
                    <Row className='ms-1 mt-4'>
                        <Button
                            style={{
                                background: '#42a5fe',
                                border: '0',
                                borderRadius: '10vw',
                                width: 'auto',
                                fontSize: '8vw',
                                fontWeight: '800',
                                paddingTop: '0.4vw',
                                paddingBottom: '0.5vw',
                            }}
                            onClick={handleButtonClick}
                        >
                            {
                                isDisconnected ? 'Connect' : 'Account'
                            }
                        </Button>
                    </Row>
                    <Row className='ms-1 mt-4'>
                        <a href="https://twitter.com/cyberclowntoken" target="_blank" rel="noopener noreferrer">
                            <FaTwitter size='7vw' color="#1DA1F2"/>
                        </a>
                    </Row>
                    <Row className='ms-1 mt-4'>
                        <a href="https://medium.com/@cyberclowntoken/welcome-to-the-clowning-revolution-cyber-clown-token-unleashed-48e5ce8c7f43" target="_blank" rel="noopener noreferrer">
                            <FaMedium size='7vw' color="#FFFFFF"/>
                        </a>
                    </Row>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}