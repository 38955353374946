import Image from "react-bootstrap/Image";

export function AboutIntroduction2Pc() {
    return (
        <div className='position-relative mt-3' style={{maxWidth: '100%'}}>
            <Image src="images/about/pc/introduction2BG.png" style={{width: '100%', height: 'auto'}}/>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '13.1%',
                left: '36.2%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '1.5vw',
                fontWeight: '500',
                fontStyle: 'italic',
                lineHeight:'120%'
            }}>
                The Cyber Clown Token<br/>
                Experience
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '19%',
                left: '20%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '1vw',
                fontWeight: '500',
                lineHeight:'200%'
            }}>
                With a total token supply of <spen style={{fontWeight: '700'}}>180,500,000,000,</spen> we're bringing the circus to the<br/>
                blockchain like never before. Cyber Clown Token is your ticket to a realm of<br/>
                endless possibilities, where laughs meet talent, and where rewards rain down<br/>
                like confetti.
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '28.2%',
                left: '35%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '1.65vw',
                fontWeight: '500',
                fontStyle: 'italic'
            }}>
                Tokenomics that Make Sense
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '33.9%',
                left: '19%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '0.9vw',
                fontWeight: '500',
                lineHeight:'200%'
            }}>
                <spen style={{fontWeight: '700'}}>We believe in transparency and community-driven growth.</spen> That's why we've allocated<br/>
                40% for our Presale, ensuring early supporters get in on the fun. Another 50% goes into<br/>
                Liquidity, securing a stable foundation for the project. The remaining 10% is reserved for<br/>
                our dedicated Community Management Team, the ringmasters of this circus.
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '43.7%',
                left: '40.5%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '1.65vw',
                fontWeight: '500',
                fontStyle: 'italic'
            }}>
                The Phases of Fun
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '49.3%',
                left: '20%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '0.9vw',
                fontWeight: '500',
                lineHeight:'200%'
            }}>
                But hold on to your clown hats, because the real excitement begins in Phase 2! We're<br/>
                launching the Gamestar+ platform, a gaming paradise like no other. It's where<br/>
                <spen style={{fontWeight: '700'}}>entertainment and blockchain collide,</spen> with the <spen style={{fontWeight: '700'}}>integration of the cutting-edge</spen><br/>
                <spen style={{fontWeight: '700'}}>ERC-3225 standard.</spen> Get ready for a gaming experience that's out of this world.
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '58.5%',
                left: '37%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '1.65vw',
                fontWeight: '500',
                fontStyle: 'italic'
            }}>
                Empowering the Community
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '64.2%',
                left: '18%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '0.905vw',
                fontWeight: '500',
                lineHeight:'200%'
            }}>
                And that's not all, folks! In Phase 3, <spen style={{fontWeight: '700'}}>we're putting the power in your hands with the Talent</spen><br/>
                <spen style={{fontWeight: '700'}}>Voting Platform.</spen> You, the incredible Cyber Clown Token community members, will have<br/>
                the say in which talents we support next. It's a true democracy of laughter, where your<br/>
                voice matters.
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '76.1%',
                left: '39.6%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '1.65vw',
                fontWeight: '500',
                fontStyle: 'italic'
            }}>
                Join the Circus
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '82.8%',
                left: '21.5%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '0.93vw',
                fontWeight: '500',
                lineHeight:'200%'
            }}>
                So, why Cyber Clown Token?<br/>
                Because we're not just another project; <spen style={{fontWeight: '700'}}>we're a movement.</spen><br/>
                <spen style={{fontWeight: '700'}}>We're about laughter and talent, about fairness and fun.</spen><br/>
                We're here to make the internet a better place, where deserving creators shine,<br/>
                and the community thrives.<br/>
                <br/>
                Don your clown nose and become part of the circus.<br/>
                Cyber Clown Token is more than just a cryptocurrency; it's a celebration of<br/>
                <spen style={{fontWeight: '700'}}>creativity, a symphony of laughter, and a promise of exciting things to come.</spen><br/>
                <br/>
                Welcome to the future of entertainment.<br/>
                <spen style={{fontWeight: '700'}}>Welcome to Cyber Clown Token. 🌟🤡🎉</spen>
            </div>
        </div>
    )
}