import Image from "react-bootstrap/Image";

export function MainIntroduction2Pc() {
    return (
        <div className='mt-4 position-relative' style={{maxWidth: '100%'}}>
            <Image src="images/main/pc/introduction2BG.png" style={{width: '100%', height: 'auto'}}/>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '12.75%',
                left: '41.5%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '1.62vw',
                lineHeight: '1.2',
                fontWeight: '600',
                fontStyle: 'italic',
            }}>
                Unmasking Real Talent
            </div>
            <div className='position-absolute w-100 my-text' style={{
                textAlign: 'center',
                top: '42%',
                left: '50%',
                transform: 'translate(-50%, 0%)',
                color: 'black',
                fontSize: '0.9vw',
                fontWeight: '500',
            }}>
                You know how the internet loves its instant stars or should we say people with no real<br/>
                talent?<br/>
                But what about the creators who put their heart and soul into their craft and barely get a<br/>
                high-five?<br/>
                <div style={{fontWeight: '800'}}>
                    We're trying to find those unsung heroes, those digital wizards who deserve the<br/>
                    spotlight.
                </div>
            </div>
        </div>
    )
}