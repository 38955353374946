import React, {useState, useEffect} from 'react';
import cctABI from '../../../../ABI/cctABI.json';
import cctTokenABI from '../../../../ABI/cctTokenABI.json';

import {useContractRead} from "wagmi";
import {ethers} from "ethers";

const D223SoldFramePC = ({width, height, bottom, left}) => {
    function formatNumberWithCommas(value) {
        return new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(value);
    }

    const { data: totalSupplyData } = useContractRead({
        address: '0xEC867d44E6E24C0271294468cfDdCF1b84384EEF',
        abi: cctTokenABI,
        functionName: 'totalSupply',
        watch: true,
    });

    const { data: cctBalanceData } = useContractRead({
        address: '0x43c2fd02e50b5b3c8b289a987dac98ceaf933514',
        abi: cctABI,
        functionName: 'getCCTBalance',
        watch: true,
    });

    // 格式化或处理返回的数据
    const totalSupply = totalSupplyData
        ? parseFloat(ethers.utils.formatUnits(totalSupplyData, 'ether') * 0.5).toFixed(2)
        : 0.00;

    const cctBalance = cctBalanceData
        ? parseFloat(ethers.utils.formatUnits(cctBalanceData, 'ether')).toFixed(2)
        : 0.00;

    // 计算已售出的数量
    const soldData = totalSupply - cctBalance;
    const percent = ((soldData / totalSupply) * 100).toString() + "%";

    return (
        <div style={{
            position: 'absolute',
            width: width,
            height: height,
            backgroundColor: 'transparent',
            border: '0.15vw solid white',
            bottom: bottom,
            left: left,
            borderRadius: '0.5vw',
            transform: 'translate(0%, -50%)'
        }}>
            {/* 其他內容 */}
            <div style={{
                width: percent,
                height: '100%',
                borderRadius: '0.25vw',
                backgroundColor: '#FBB616',
            }}></div>
            <div className='mt-2' style={{
                fontSize: '1vw',
                color: 'white',
                width: '150%'
            }}>
                CCT Sold: {formatNumberWithCommas(soldData)} / {formatNumberWithCommas(totalSupply)}
            </div>
        </div>
    );
}

export default D223SoldFramePC;