import React, { useState, useEffect } from 'react';
import './CountdownTimerMobile.css'; // 確保引入CSS

const NumberSliderMobile = ({ value }) => {
    const [prevValue, setPrevValue] = useState(value);
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        if (value !== prevValue) {
            setAnimate(true);
            setTimeout(() => {
                setPrevValue(value);
                setAnimate(false);
            }, 500); // 动画持续时间
        }
    }, [value, prevValue]);

    return (
        <div className="number-slider-m">
            <div className={`number-slider-current-m ${animate ? 'slide-out-m' : 's1-m'}`}>
                {prevValue}
            </div>
            <div className={`number-slider-next-m ${animate ? 'slide-in-m' : 's2-m'}`}>
                {value}
            </div>
        </div>
    );
};

const CountdownTimerMobile = () => {
    const formatNumber = (number) => {
        return number < 10 ? `0${number}` : number;
    };

    const calculateTimeLeft = () => {
        const difference = +new Date("2024-04-24T18:00:00") - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                D: formatNumber(Math.floor(difference / (1000 * 60 * 60 * 24))),
                H: formatNumber(Math.floor((difference / (1000 * 60 * 60)) % 24)),
                M: formatNumber(Math.floor((difference / 1000 / 60) % 60)),
                S: formatNumber(Math.floor((difference / 1000) % 60))
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    return (
        <div className="countdown-container-m">
            <NumberSliderMobile value={timeLeft.D} />
            <span className="colon-m">:</span>
            <NumberSliderMobile value={timeLeft.H} />
            <span className="colon-m">:</span>
            <NumberSliderMobile value={timeLeft.M} />
            <span className="colon-m">:</span>
            <NumberSliderMobile value={timeLeft.S} />
        </div>
    );
}

export default CountdownTimerMobile;