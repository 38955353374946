import MediaQuery from "react-responsive";
import {AboutPagePc} from "./AboutPagePc";
import {AboutPageMobile} from "./AboutPageMobile";

export function AboutPage() {
    return (
        <div>
            <MediaQuery minWidth={768}>
                {(matches) =>
                    matches
                        ? <AboutPagePc/>
                        : <AboutPageMobile/>
                }
            </MediaQuery>
        </div>
    )
}