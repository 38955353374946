import {Button} from "react-bootstrap";
import {useAccount} from 'wagmi'
import {useWeb3Modal} from '@web3modal/wagmi/react'
import {FaTwitter, FaMedium} from 'react-icons/fa';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import {useState} from 'react'
import {useContractWrite} from 'wagmi'
import {parseEther} from 'viem'

import cctABI from '../../../../ABI/cctABI.json';

import {Link} from 'react-router-dom';
import Image from "react-bootstrap/Image";

export const NavbarPc = () => {
    const {isDisconnected} = useAccount()
    const {open} = useWeb3Modal()

    function handleButtonClick() {
        open();
    }

    return (
        <div className='position-relative' style={{maxWidth: '100%', height: '4.25vw'}}>
            <div className='position-absolute top-50' style={{
                left: '2.25%',
                transform: 'translate(0%, -50%)',
            }}>
                <Image src="images/logo.png" style={{width: '3vw', height: 'auto'}}/>
            </div>
            <div className='position-absolute top-50' style={{
                right: '42%',
                transform: 'translate(0%, -50%)',
            }}>
                <Link to="/"
                      style={{color: 'white', fontSize: '1vw', fontWeight: '800', textDecoration: 'none'}}>Home</Link>
            </div>
            <div className='position-absolute top-50' style={{
                right: '34%',
                transform: 'translate(0%, -50%)',
            }}>
                <Link to="/about"
                      style={{color: 'white', fontSize: '1vw', fontWeight: '800', textDecoration: 'none'}}>About</Link>
            </div>
            <div className='align-items-center h-100 d-flex'>
                <a href="https://twitter.com/cyberclowntoken" target="_blank" rel="noopener noreferrer" className='position-absolute' style={{
                    right: '26%',
                }}>
                    <FaTwitter size='1.75vw' color="#1DA1F2"/>
                </a>
                <a href="https://medium.com/@cyberclowntoken/welcome-to-the-clowning-revolution-cyber-clown-token-unleashed-48e5ce8c7f43" target="_blank" rel="noopener noreferrer" className='position-absolute' style={{
                    right: '18%',
                }}>
                    <FaMedium size='1.75vw' color="#FFFFFF"/>
                </a>
            </div>
            <div className='position-absolute top-50' style={{
                right: '3%',
                transform: 'translate(0%, -50%)',
            }}>
                <Button
                    style={{
                        background: '#42a5fe',
                        border: '0',
                        borderRadius: '10vw',
                        width: 'auto',
                        fontSize: '1vw',
                        fontWeight: '800',
                        paddingLeft: '0.5vw',
                        paddingRight: '0.5vw',
                        paddingTop: '0.2vw',
                        paddingBottom: '0.2vw',
                    }}
                    onClick={handleButtonClick}
                >
                    {
                        isDisconnected ? 'Connect' : 'Account'
                    }
                </Button>
            </div>
        </div>
    )
}