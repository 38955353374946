import Image from "react-bootstrap/Image";

export function MainIntroduction3Pc() {
    return (
        <div className='mt-4 position-relative' style={{maxWidth: '100%', background: '#000000'}}>
            <Image src="images/main/pc/introduction3BG.png" style={{width: '100%', height: 'auto'}}/>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '12.75%',
                left: '41.5%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '1.6vw',
                lineHeight: '1.2',
                fontWeight: '600',
                fontStyle: 'italic',
            }}>
                Raise The Clowns
            </div>
            <div className='position-absolute w-100 my-text' style={{
                textAlign: 'center',
                top: '43.5%',
                left: '50%',
                transform: 'translate(-50%, 0%)',
                color: 'black',
                fontSize: '0.9vw',
                fontWeight: '500',
            }}>
                At Cyber Clown Token, we're all about <span style={{fontWeight: '800'}}>nurturing talent.<br/></span>
                We're not just handing out trophies; we're <span style={{fontWeight: '800'}}>providing resources</span> and a
                <span style={{fontWeight: '800'}}> community where<br/></span>
                <span style={{fontWeight: '800'}}> talent can flourish.<br/></span>
                We're the wind beneath their wings, the mentor in their journey to stardom.
            </div>
        </div>
    )
}