import Image from "react-bootstrap/Image";

export function AboutIntroduction2Mobile() {
    return (
        <div className='position-relative mt-3' style={{maxWidth: '100%'}}>
            <Image src="images/about/mobile/introduction2BG.png" style={{width: '100%', height: 'auto'}}/>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '4%',
                left: '10%',
                transform: 'translate(0%, 0%)',
                color: 'white',
                fontSize: '3.5vw',
                fontWeight: '400',
                lineHeight:"200%"
            }}>
                Ladies and gentlemen, step right up to<br/>
                witness the birth of a digital phenomenon – <br/>
                Cyber Clown Token! We're not just a <br/>
                memecoin; we're a memecoin with a mission, <br/>
                and we're here to revolutionize the way you <br/>
                experience the digital world.
            </div>
        </div>
    )
}