import Image from "react-bootstrap/Image";

export const MainIntroduction7Pc = () => {
    return (
        <div className='mt-4 position-relative' style={{maxWidth: '100%', background: '#000000'}}>
            <Image src="images/main/pc/introduction7BG.png" style={{width: '100%', height: 'auto'}}/>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '13%',
                left: '11.8%',
                transform: 'translate(0%, 0%)',
                color: '#29180f',
                fontSize: '1.3vw',
                lineHeight: '1.2',
                fontWeight: '800',
            }}>
                Phase 1
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '21%',
                left: '11.7%',
                transform: 'translate(0%, 0%)',
                color: '#1a1917',
                fontSize: '0.8vw',
                lineHeight: '1.2',
                fontWeight: '700',
            }}>
                Smart Contract Development
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '29%',
                left: '11.7%',
                transform: 'translate(0%, 0%)',
                color: '#1a1917',
                fontSize: '0.8vw',
                lineHeight: '1.2',
                fontWeight: '700',
            }}>
                Community Deploy
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'left',
                top: '36.5%',
                left: '11.7%',
                transform: 'translate(0%, 0%)',
                color: '#1a1917',
                fontSize: '0.8vw',
                lineHeight: '1.2',
                fontWeight: '700',
            }}>
                Presale live<br/>(40% of 180,500,000,000)
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '13%',
                left: '43.5%',
                transform: 'translate(0%, 0%)',
                color: '#29180f',
                fontSize: '1.3vw',
                lineHeight: '1.2',
                fontWeight: '700',
            }}>
                Phase 2
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '21%',
                left: '43.5%',
                transform: 'translate(0%, 0%)',
                color: '#1a1917',
                fontSize: '0.8vw',
                lineHeight: '1.2',
                fontWeight: '700',
            }}>
                Token Release
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '29%',
                left: '43.5%',
                transform: 'translate(0%, 0%)',
                color: '#1a1917',
                fontSize: '0.8vw',
                lineHeight: '1.2',
                fontWeight: '700',
            }}>
                DEX listing (UniSwap)
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'left',
                top: '35.4%',
                left: '43.5%',
                transform: 'translate(0%, 0%)',
                color: '#1a1917',
                fontSize: '0.8vw',
                lineHeight: '1.2',
                fontWeight: '700',
            }}>
                Development CCT<br/>Gamestar+ Programme with <br/>Integration of ERC-3225
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '13%',
                left: '75.5%',
                transform: 'translate(0%, 0%)',
                color: '#29180f',
                fontSize: '1.3vw',
                lineHeight: '1.2',
                fontWeight: '700',
            }}>
                Phase 3
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'left',
                top: '18.5%',
                left: '75.5%',
                transform: 'translate(0%, 0%)',
                color: '#1a1917',
                fontSize: '0.8vw',
                lineHeight: '1.2',
                fontWeight: '700',
            }}>
                Talent Voting Platform<br/>Deploy
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'left',
                top: '25%',
                left: '75.5%',
                transform: 'translate(0%, 0%)',
                color: '#1a1917',
                fontSize: '0.8vw',
                lineHeight: '1.2',
                fontWeight: '700',
            }}>
                NFT release to Token<br/>Holders
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'left',
                top: '32.5%',
                left: '75.5%',
                transform: 'translate(0%, 0%)',
                color: '#1a1917',
                fontSize: '0.8vw',
                lineHeight: '1.2',
                fontWeight: '700',
            }}>
                20% Token Supply Burnt
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'left',
                top: '38.4%',
                left: '75.5%',
                transform: 'translate(0%, 0%)',
                color: '#1a1917',
                fontSize: '0.8vw',
                lineHeight: '1.2',
                fontWeight: '700',
            }}>
                Clowning Our Way to 99M<br/>Market Cap
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '54.25%',
                left: '45%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '1.7vw',
                lineHeight: '1.2',
                fontWeight: '600',
                fontStyle: 'italic',
            }}>
                Tokenomics :
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '85%',
                left: '9.75%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '1.7vw',
                lineHeight: '1.2',
                fontWeight: '500',
                fontStyle: 'italic',
            }}>
                40% Presale
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '85%',
                left: '44.5%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '1.7vw',
                lineHeight: '1.2',
                fontWeight: '500',
                fontStyle: 'italic',
            }}>
                50% Liquidity
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '84%',
                left: '77.5%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '1.7vw',
                lineHeight: '1.2',
                fontWeight: '500',
                fontStyle: 'italic',
            }}>
                10% Management<br/>Team
            </div>
        </div>
    )
}