import Image from "react-bootstrap/Image";

export function MainIntroduction4Pc() {
    return (
        <div className='mt-4 position-relative' style={{maxWidth: '100%', background: '#000000'}}>
            <Image src="images/main/pc/introduction4BG.png" style={{width: '100%', height: 'auto'}}/>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '28.25%',
                left: '35s%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '1.6vw',
                lineHeight: '1.2',
                fontWeight: '600',
                fontStyle: 'italic',
            }}>
                More Than Memes, More Than Magic
            </div>
            <div className='position-absolute w-100 my-text' style={{
                textAlign: 'center',
                top: '49%',
                left: '50%',
                transform: 'translate(-50%, 0%)',
                color: 'black',
                fontSize: '0.9vw',
                fontWeight: '500',
            }}>
                Sure, we've got memes – <span style={{fontWeight: '800'}}>who doesn't love a good laugh?<br/></span>
                But we're more than just gags and guffaws.<br/>
                We're the <span style={{fontWeight: '800'}}>place where memes meet meaning</span>, where entertainment and enlightenment<br/>
                <span style={{fontWeight: '600'}}>shake hands, and </span>where the internet gets less stupidity.<br/>
                <span style={{fontWeight: '800'}}>So, let us start the party, continue the clowning,</span> and
                <span style={{fontWeight: '800'}}> get ready for a standing ovation!</span>
            </div>
        </div>
    )
}