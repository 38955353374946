import Image from "react-bootstrap/Image";

export function MainIntroduction5Mobile() {
    return (
        <div className='position-relative mt-3' style={{maxWidth: '100%'}}>
            <Image src="images/main/mobile/introduction5BG.png" style={{width: '100%', height: 'auto'}}/>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '10%',
                left: '39%',
                transform: 'translate(0%, 0%)',
                color: '#29180F',
                fontSize: '5vw',
                fontWeight: '900',
            }}>
                Phase 1
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '15.8%',
                left: '37%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '3.5vw',
                fontWeight: '700',
            }}>
                Token Development<br/>
                (180,500,000,000 CCT)
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '23.8%',
                left: '37%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '3.5vw',
                fontWeight: '700',
            }}>
                Community Deploy
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '31%',
                left: '37%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '3.5vw',
                fontWeight: '700',
            }}>
                Presale Live!
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '42.4%',
                left: '39%',
                transform: 'translate(0%, 0%)',
                color: '#29180F',
                fontSize: '5vw',
                fontWeight: '900',
            }}>
                Phase 2
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '48.6%',
                left: '37%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '3.5vw',
                fontWeight: '700',
            }}>
                Token Release
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '55.6%',
                left: '37%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '3.4vw',
                fontWeight: '700',
            }}>
                NFT Release
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '62.6%',
                left: '37%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '3.5vw',
                fontWeight: '700',
            }}>
                DEX Listing
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '73.6%',
                left: '39%',
                transform: 'translate(0%, 0%)',
                color: '#29180F',
                fontSize: '5vw',
                fontWeight: '900',
            }}>
                Phase 3
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'left',
                top: '79%',
                left: '37%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '3.5vw',
                fontWeight: '700',
            }}>
                Merchandise Release<br/>
                For Token Holders
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'center',
                top: '87.2%',
                left: '37%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '3.5vw',
                fontWeight: '700',
            }}>
                20% Token Supply Burnt
            </div>
            <div className='position-absolute my-text' style={{
                textAlign: 'left',
                top: '93.4%',
                left: '37%',
                transform: 'translate(0%, 0%)',
                color: 'black',
                fontSize: '3.5vw',
                fontWeight: '700',
            }}>
                Clowning Our Way to<br/>
                99M Market Cap
            </div>
        </div>
    )
}