import Image from "react-bootstrap/Image";
import React, {useState, useEffect} from 'react';
import CountdownTimerPc from "./CountdownTimerPc";
import D223SoldFramePC from "./D223SoldFramePC";
import CCTCountPC from "./CCTCountPC";
import ConnectOrBuyButtonPC from "./ConnectOrBuyButtonPC";

export function NewWelcomePc() {
    const [ethCount, setEthCount] = useState(0);

    return (
        <div className='position-relative' style={{maxWidth: '100%'}}>
            <Image src="images/main/pc/New_WelcomeBG.png" style={{width: '100%', height: 'auto'}}/>

            {/*倒數時間條*/}
            <Image src="images/main/pc/timeCounterBG_2.png" className='position-absolute' style={{
                width: '46.5%',
                bottom: '5.25%',
                left: '2.25%',
                transform: 'translate(0%, -50%)',
            }}>
            </Image>
            <div className='position-absolute' style={{
                width: '46.5%',
                bottom: '19.5%',
                left: '2.25%',
                color: "white",
                transform: 'translate(0%, -50%)',
            }}>
                <CountdownTimerPc></CountdownTimerPc>
            </div>

            <CCTCountPC width={'45%'} height={'27.5%'} top={'57.5%'} left={'46%'} onResultChange={(value) => setEthCount(value)}></CCTCountPC>
            <D223SoldFramePC width={'33.5%'} height={'3.75%'} bottom={'8%'} left={'9%'}></D223SoldFramePC>
            <ConnectOrBuyButtonPC width={'30%'} height={'6.5%'} bottom={'7.5%'} left={'54%'} buyEthValue={ethCount}></ConnectOrBuyButtonPC>
        </div>
    )
}